export const themeColors = {
	red_50: "#ffebee",
	red_100: "#ffcdd2",
	red_200: "#ef9a9a",
	red_300: "#e57373",
	red_400: "#ef5350",
	red_500: "#f44336",
	red_600: "#e53935",
	red_700: "#d32f2f",
	red_800: "#c62828",
	red_900: "#b71c1c",

	pink_50: "#fce4ec",
	pink_100: "#f8bbd0",
	pink_200: "#f48fb1",
	pink_300: "#f06292",
	pink_400: "#ec407a",
	pink_500: "#e91e63",
	pink_600: "#d81b60",
	pink_700: "#c2185b",
	pink_800: "#ad1457",
	pink_900: "#880e4f",

	purple_50: "#f3e5f5",
	purple_100: "#e1bee7",
	purple_200: "#ce93d8",
	purple_300: "#ba68c8",
	purple_400: "#ab47bc",
	purple_500: "#9c27b0",
	purple_600: "#8e24aa",
	purple_700: "#7b1fa2",
	purple_800: "#6a1b9a",
	purple_900: "#4a148c",

	deepPurple_50: "#ede7f6",
	deepPurple_100: "#d1c4e9",
	deepPurple_200: "#b39ddb",
	deepPurple_300: "#9575cd",
	deepPurple_400: "#7e57c2",
	deepPurple_500: "#673ab7",
	deepPurple_600: "#5e35b1",
	deepPurple_700: "#512da8",
	deepPurple_800: "#4527a0",
	deepPurple_900: "#311b92",

	indigo_50: "#e8eaf6",
	indigo_100: "#c5cae9",
	indigo_200: "#9fa8da",
	indigo_300: "#7986cb",
	indigo_400: "#5c6bc0",
	indigo_500: "#3f51b5",
	indigo_600: "#3949ab",
	indigo_700: "#303f9f",
	indigo_800: "#283593",
	indigo_900: "#1a237e",

	blue_50: "#e3f2fd",
	blue_100: "#bbdefb",
	blue_200: "#90caf9",
	blue_300: "#64b5f6",
	blue_400: "#42a5f5",
	blue_500: "#2196f3",
	blue_600: "#1e88e5",
	blue_700: "#1976d2",
	blue_800: "#1565c0",
	blue_900: "#0d47a1",

	lightBlue_50: "#e1f5fe",
	lightBlue_100: "#b3e5fc",
	lightBlue_200: "#81d4fa",
	lightBlue_300: "#4fc3f7",
	lightBlue_400: "#29b6f6",
	lightBlue_500: "#03a9f4",
	lightBlue_600: "#039be5",
	lightBlue_700: "#0288d1",
	lightBlue_800: "#0277bd",
	lightBlue_900: "#01579b",

	cyan_50: "#e0f7fa",
	cyan_100: "#b2ebf2",
	cyan_200: "#80deea",
	cyan_300: "#4dd0e1",
	cyan_400: "#26c6da",
	cyan_500: "#00bcd4",
	cyan_600: "#00acc1",
	cyan_700: "#0097a7",
	cyan_800: "#00838f",
	cyan_900: "#006064",

	teal_50: "#e0f2f1",
	teal_100: "#b2dfdb",
	teal_200: "#80cbc4",
	teal_300: "#4db6ac",
	teal_400: "#26a69a",
	teal_500: "#009688",
	teal_600: "#00897b",
	teal_700: "#00796b",
	teal_800: "#00695c",
	teal_900: "#004d40",

	green_50: "#e8f5e9",
	green_100: "#c8e6c9",
	green_200: "#a5d6a7",
	green_300: "#81c784",
	green_400: "#66bb6a",
	green_500: "#4caf50",
	green_600: "#43a047",
	green_700: "#388e3c",
	green_800: "#2e7d32",
	green_900: "#1b5e20",

	lightGreen_50: "#f1f8e9",
	lightGreen_100: "#dcedc8",
	lightGreen_200: "#c5e1a5",
	lightGreen_300: "#aed581",
	lightGreen_400: "#9ccc65",
	lightGreen_500: "#8bc34a",
	lightGreen_600: "#7cb342",
	lightGreen_700: "#689f38",
	lightGreen_800: "#558b2f",
	lightGreen_900: "#33691e",

	lime_50: "#f9fbe7",
	lime_100: "#f0f4c3",
	lime_200: "#e6ee9c",
	lime_300: "#dce775",
	lime_400: "#d4e157",
	lime_500: "#cddc39",
	lime_600: "#c0ca33",
	lime_700: "#afb42b",
	lime_800: "#9e9d24",
	lime_900: "#827717",

	yellow_50: "#fffde7",
	yellow_100: "#fff9c4",
	yellow_200: "#fff59d",
	yellow_300: "#fff176",
	yellow_400: "#ffee58",
	yellow_500: "#ffeb3b",
	yellow_600: "#fdd835",
	yellow_700: "#fbc02d",
	yellow_800: "#f9a825",
	yellow_900: "#f57f17",

	amber_50: "#fff8e1",
	amber_100: "#ffecb3",
	amber_200: "#ffe082",
	amber_300: "#ffd54f",
	amber_400: "#ffca28",
	amber_500: "#ffc107",
	amber_600: "#ffb300",
	amber_700: "#ffa000",
	amber_800: "#ff8f00",
	amber_900: "#ff6f00",

	orange_50: "#fff3e0",
	orange_100: "#ffe0b2",
	orange_200: "#ffcc80",
	orange_300: "#ffb74d",
	orange_400: "#ffa726",
	orange_500: "#ff9800",
	orange_600: "#fb8c00",
	orange_700: "#f57c00",
	orange_800: "#ef6c00",
	orange_900: "#e65100",
	deepOrange_50: "#fbe9e7",
	deepOrange_100: "#ffccbc",
	deepOrange_200: "#ffab91",
	deepOrange_300: "#ff8a65",
	deepOrange_400: "#ff7043",
	deepOrange_500: "#ff5722",
	deepOrange_600: "#f4511e",
	deepOrange_700: "#e64a19",
	deepOrange_800: "#d84315",
	deepOrange_900: "#bf360c",

	brown_50: "#efebe9",
	brown_100: "#d7ccc8",
	brown_200: "#bcaaa4",
	brown_300: "#a1887f",
	brown_400: "#8d6e63",
	brown_500: "#795548",
	brown_600: "#6d4c41",
	brown_700: "#5d4037",
	brown_800: "#4e342e",
	brown_900: "#3e2723",

	blueGrey_50: "#eceff1",
	blueGrey_100: "#cfd8dc",
	blueGrey_200: "#b0bec5",
	blueGrey_300: "#90a4ae",
	blueGrey_400: "#78909c",
	blueGrey_500: "#607d8b",
	blueGrey_600: "#546e7a",
	blueGrey_700: "#455a64",
	blueGrey_800: "#37474f",
	blueGrey_900: "#263238",

	grey_50: "#fafafa",
	grey_100: "#f5f5f5",
	grey_200: "#eeeeee",
	grey_300: "#e0e0e0",
	grey_400: "#bdbdbd",
	grey_500: "#9e9e9e",
	grey_600: "#757575",
	grey_700: "#616161",
	grey_800: "#424242",
	grey_900: "#212121",
};
